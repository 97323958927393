
import StartScreen from "./components/StartScreen.vue";
import { defineComponent } from "vue";
import DESIGN_TYPE from "./main";
import store from "./store";
import ModalList from "@/components/Modals/ModalLIst.vue";
import { AudioUtils, debounce, loadingAllSounds } from "./utils";
import { GameId } from "@/types/user";
import { api } from "./api";
import {
  astroImgs,
  gambusakiImgs,
  khojaImgs,
  kissahoImgs,
  mevahoImgs,
  misrImgs,
  pamirImgs,
  pearlImgs,
  silkWayImgs,
  mevahoGoldImgs,
} from "./image-loader";

export default defineComponent({
  components: {
    ModalList,
    StartScreen,
  },
  data() {
    return {
      debouncedResizeHandler: undefined as Function | undefined,
      ro: undefined as ResizeObserver | undefined,
      isLoading: true,
      showLoadingScreen: true,
      loadedImagesCount: 0,
      loadedImages: 0,
    };
  },
  mounted() {
    this.loadImages();
    loadingAllSounds();
    //переход на другую вкладку браузера
    window.addEventListener("freeze", this.stopAudio);
    window.addEventListener("pagehide", this.stopAudio);
    //блокировка экрана
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.stopAudio();
      } else {
        this.autoClick();
      }
    });
  },
  computed: {
    designType(): string {
      return `--${DESIGN_TYPE}`;
    },
    gameCss(): object {
      return {
        "--mevaho": this.gameId === "loto-mevaho-5",
        "--gambusaki": this.gameId === "loto-gambusaki-9",
        "--gambusaki-gold": this.gameId === "loto-gambusaki-gold-9",
        "--khoja": this.gameId === "loto-khoja",
        "--kissaho": this.gameId === "loto-kissaho",
        "--pearl": this.gameId === "loto-pearl",
        "--silk-way": this.gameId === "loto-silk-way",
        "--misr": this.gameId === "loto-misr",
        "--pamir-legends": this.gameId === "loto-pamir-legends",
        "--mevaho-gold": this.gameId === "loto-mevaho-gold",
        "--astrologer": this.gameId === "loto-astrologer",
        "--fullscreen": this.isWidescreen && this.isDesktop,
      };
    },
    isAppLoaded(): boolean {
      return store.state.ui.isAppLoaded;
    },
    gameId(): GameId {
      return store.state.currentGameId;
    },
    isWidescreen(): boolean {
      return store.state.userData?.settings?.widescreen === "on";
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    isFruitMode(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit";
    },
    isBonusGame(): boolean {
      return store.state.bonus.isOn;
    },
    progress(): number {
      return Math.min(Math.round((this.loadedImages / this.loadedImagesCount) * 100), 100);
    },
  },
  methods: {
    resizeHandler() {
      store.commit("forceResize");
      // update mobile/desktop version after size changes
      store.dispatch("updateUIVersion");
    },
    onchange(e: any) {
      const userAgent = navigator.userAgent || navigator.vendor;
      if (/Macintosh|iPad|iPhone|iPod/.test(userAgent)) {
        console.log("connection offline for ios: ", e);
        api.disconnectFixIos();
      }
    },
    playSound() {
      AudioUtils.resumeAudioContext();
      if (this.isFruitMode && !this.isBonusGame) {
        if (this.gameId === "loto-pamir-legends") {
          AudioUtils.stopBackgroundMusic("loto-pamir-legends", "main");
          AudioUtils.stopBackgroundMusic("loto-pamir-legends", "bonus");
          AudioUtils.playBackgroundMusic("loto-pamir-legends", "main");
        } else if (this.gameId === "loto-pearl") {
          AudioUtils.stopBackgroundMusic("loto-pearl", "main");
          AudioUtils.stopBackgroundMusic("loto-pearl", "bonus");
          AudioUtils.playBackgroundMusic("loto-pearl", "main");
        } else if (this.gameId === "loto-misr") {
          AudioUtils.stopBackgroundMusic("loto-misr", "main");
          AudioUtils.stopBackgroundMusic("loto-misr", "bonus");
          AudioUtils.playBackgroundMusic("loto-misr", "main");
        } else if (this.gameId === "loto-khoja") {
          AudioUtils.stopBackgroundMusic("loto-khoja", "main");
          AudioUtils.stopBackgroundMusic("loto-khoja", "bonus");
          AudioUtils.playBackgroundMusic("loto-khoja", "main");
        } else if (this.gameId === "loto-astrologer") {
          AudioUtils.stopBackgroundMusic("loto-astrologer", "main");
          AudioUtils.stopBackgroundMusic("loto-astrologer", "bonus");
          AudioUtils.playBackgroundMusic("loto-astrologer", "main");
        } else if (this.gameId === "loto-kissaho") {
          AudioUtils.stopBackgroundMusic("loto-kissaho", "main");
          AudioUtils.stopBackgroundMusic("loto-kissaho", "bonus");
          AudioUtils.playBackgroundMusic("loto-kissaho", "main");
        } else if (this.gameId === "loto-mevaho-gold") {
          AudioUtils.stopBackgroundMusic("loto-mevaho-gold", "main");
          AudioUtils.playBackgroundMusic("loto-mevaho-gold", "main");
        }
        console.log("playsound created");
      } else if (this.isFruitMode && this.isBonusGame) {
        if (this.gameId === "loto-pamir-legends") {
          AudioUtils.stopBackgroundMusic("loto-pamir-legends", "main");
          AudioUtils.stopBackgroundMusic("loto-pamir-legends", "bonus");
          AudioUtils.playBackgroundMusic("loto-pamir-legends", "bonus");
        } else if (this.gameId === "loto-pearl") {
          AudioUtils.stopBackgroundMusic("loto-pearl", "main");
          AudioUtils.stopBackgroundMusic("loto-pearl", "bonus");
          AudioUtils.playBackgroundMusic("loto-pearl", "bonus");
        } else if (this.gameId === "loto-misr") {
          AudioUtils.stopBackgroundMusic("loto-misr", "main");
          AudioUtils.stopBackgroundMusic("loto-misr", "bonus");
          AudioUtils.playBackgroundMusic("loto-misr", "bonus");
        } else if (this.gameId === "loto-khoja") {
          AudioUtils.stopBackgroundMusic("loto-khoja", "main");
          AudioUtils.stopBackgroundMusic("loto-khoja", "bonus");
          AudioUtils.playBackgroundMusic("loto-khoja", "bonus");
        } else if (this.gameId === "loto-astrologer") {
          AudioUtils.stopBackgroundMusic("loto-astrologer", "main");
          AudioUtils.stopBackgroundMusic("loto-astrologer", "bonus");
          AudioUtils.playBackgroundMusic("loto-astrologer", "bonus");
        } else if (this.gameId === "loto-kissaho") {
          AudioUtils.stopBackgroundMusic("loto-kissaho", "main");
          AudioUtils.stopBackgroundMusic("loto-kissaho", "bonus");
          AudioUtils.playBackgroundMusic("loto-kissaho", "bonus");
        }
      }
    },
    autoClick() {
      if (
        this.gameId === "loto-pamir-legends" ||
        this.gameId === "loto-pearl" ||
        this.gameId === "loto-misr" ||
        this.gameId === "loto-khoja" ||
        this.gameId === "loto-astrologer" ||
        this.gameId === "loto-kissaho" ||
        this.gameId === "loto-mevaho-gold"
      ) {
        setTimeout(() => {
          if (this.$refs.autoClickButton) {
            if (this.isDesktop) {
              const clickEvent = new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              });
              (this.$refs.autoClickButton as HTMLButtonElement).dispatchEvent(clickEvent);
              console.log("btn click mounted");
            } else if (!this.isDesktop) {
              const touchEvent = new TouchEvent("touchstart", {
                bubbles: true,
                cancelable: true,
                view: window,
              });
              (this.$refs.autoClickButton as HTMLButtonElement).dispatchEvent(touchEvent);
              console.log("btn touchstart");
            }
          }
        }, 500);
      }
    },
    async loadImages() {
      this.isLoading = true;

      const imagesToLoad =
        this.gameId === "loto-kissaho"
          ? kissahoImgs
          : this.gameId === "loto-khoja"
          ? khojaImgs
          : this.gameId === "loto-gambusaki-9" || this.gameId === "loto-gambusaki-gold-9"
          ? gambusakiImgs
          : this.gameId === "loto-astrologer"
          ? astroImgs
          : this.gameId === "loto-pamir-legends"
          ? pamirImgs
          : this.gameId === "loto-pearl"
          ? pearlImgs
          : this.gameId === "loto-misr"
          ? misrImgs
          : this.gameId === "loto-silk-way"
          ? silkWayImgs
          : this.gameId === "loto-mevaho-gold"
          ? mevahoGoldImgs
          : // : this.gameId === "loto-mevaho-5"
            // ? mevahoImgs
            [];

      if (this.gameId === "loto-mevaho-5") {
        this.loadedImages = 4;
        this.loadedImagesCount = 5;
      } else {
        this.loadedImagesCount = imagesToLoad.length;
        await this.preloadImages(imagesToLoad);
      }

      this.isLoading = false;
    },
    async preloadImages(imageUrls: string[]): Promise<void> {
      const promises = imageUrls.map(this.loadImage);

      try {
        await Promise.all(promises);
        console.log("Все изображения успешно загружены");
      } catch (error) {
        console.error("Ошибка загрузки изображений:", error);
      }
    },

    loadImage(src: string): Promise<void> {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          this.loadedImages++;

          resolve();
        };
        img.onerror = () => {
          this.loadedImages++;

          reject;
        };
        img.src = src;
      });
    },
    stopAudio() {
      AudioUtils.stopAllBackgroundMusic();
    },
  },
  created() {
    this.debouncedResizeHandler = debounce(this.resizeHandler, 250);
    this.ro = new ResizeObserver(() => this.debouncedResizeHandler!());
    this.ro.observe(document.body);
    if (!this.ro) {
      window.addEventListener("resize", this.debouncedResizeHandler as any);
    }
    store.dispatch("updateUIVersion");
    window.addEventListener("offline", this.onchange);
  },
  beforeUnmount() {
    window.removeEventListener("offline", this.onchange);
    window.removeEventListener("pagehide", this.stopAudio);
    window.removeEventListener("freeze", this.stopAudio);

    document.removeEventListener("visibilitychange", this.stopAudio);
  },
  watch: {
    isAppLoaded: function (n) {
      if (n && !this.isLoading) {
        this.showLoadingScreen = false;
        this.autoClick();
      }
    },
    isLoading(n) {
      if (n === false && this.isAppLoaded) {
        this.showLoadingScreen = false;
        this.autoClick();
      }
    },
    gameId: function (n) {
      if (n) {
        this.loadImages();
        loadingAllSounds();
      }
    },
  },
});
